import React, { useState, useEffect } from 'react';
import './Navbar.css';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import logo1 from '../../imagesfor/logo1.png';
import { HashLink as Link } from 'react-router-hash-link';
import { Scroll } from '../Scroll';

const drawerWidth = 240;
const navItems = [
  { label: 'الرئيسية', path: '/#header' },
  { label: 'من نحن', path: '/#about' },
  { label: 'خدماتنا', path: '/#services' },
  { label: 'طلب الخدمة', path: '/#about1' },
  { label: 'تواصل معنا', path: '/#footer' },
];

function Navbar(props) {
  const { window: windowProp } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <img src={logo1} alt="Logo" style={{ maxHeight: '80px', marginRight: '0rem' }} />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.label} disablePadding>
            <ListItemButton
              component={Link}
              smooth
              to={item.path}
              sx={{ textAlign: 'center', fontSize: '2rem' }}
            >
              <ListItemText
                sx={{ '&css-10hburv-MuiTypography-root': { fontFamily: 'Readex Pro' } }}
                primary={item.label}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = windowProp !== undefined ? () => windowProp().document.body : undefined;

  return (
    <Box sx={{ display: 'flex', direction: 'rtl' }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          backgroundColor: 'white',
          transition: 'height 0.3s',
          height: scrolled ? '70px' : '100px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Toolbar>
          <IconButton
            color="black"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, ml: 'auto' }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: 'block', sm: 'block' },
              textAlign: { xs: 'left', sm: 'right' },
              marginRight: { xs: '0rem', sm: '4rem' },
            }}
          >
            <img
              src={logo1}
              alt="Logo"
              style={{
                maxHeight: scrolled ? '50px' : '70px',
                marginLeft: '0.5rem',
                marginTop: '1rem',
              }}
            />
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <Button
                key={item.label}
                component={Link}
                to={item.path}
                scroll={(el) => Scroll(el)}
                smooth
                sx={{
                  color: '#00223f',
                  fontFamily: 'Readex Pro',
                  marginLeft: '2rem',
                  fontSize: '15px',
                  '&:hover': {
                    backgroundColor: '#D97A07',
                    color: 'white',
                  },
                }}
              >
                {item.label}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

Navbar.propTypes = {
  window: PropTypes.func,
};

export default Navbar;
