import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import HandymanIcon from '@mui/icons-material/Handyman';
import SettingsIcon from '@mui/icons-material/Settings';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: 'black',
}));

const Services = () => {
  return (
    <Box id="services" sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: '4rem', margin: '4rem' }}>
      <Typography
        style={{
          fontSize: '35px',
          fontWeight: 'bold',
          marginBottom: '0.2rem',
          lineHeight: '1',
          textDecoration: 'underline',
          textDecorationColor: '#ff6900',
          fontFamily: 'Readex Pro',
          color: 'rgb(0, 34, 63)'
        }}>
        خدماتنا
      </Typography>
      <Box sx={{ flexGrow: 1, marginTop: '4rem', direction: 'rtl' }}>
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6} md={4}>
            <Item>
              <ApartmentIcon sx={{ fontSize: 70, color: '#D97A07' }} />
              <Typography variant="h5" sx={{ mb: 2, fontFamily: 'Readex Pro' }}>
                فحص هيكل المبنى
              </Typography>
              <Typography sx={{ mb: 4, fontFamily: 'Readex Pro', marginTop: '2rem' }}>
                قوم بتقييم الهيكل الإنشائي للمبنى بما في ذلك الأساسات والأعمدة والجدران والسقوف لضمان سلامتها وقوتها الهيكلية.
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Item>
              <TrendingUpIcon sx={{ fontSize: 70, color: '#D97A07' }} />
              <Typography variant="h5" sx={{ mb: 2, fontFamily: 'Readex Pro' }}>
                تقييم الأداء الهندسي
              </Typography>
              <Typography sx={{ mb: 4, fontFamily: 'Readex Pro', marginTop: '2rem' }}>
                نقوم بتحليل أداء المبنى من حيث المقاومة للزلازل والحماية من الحرائق والعوامل البيئية الأخرى.
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Item>
              <HandymanIcon sx={{ fontSize: 70, color: '#D97A07' }} />
              <Typography variant="h5" sx={{ mb: 2, fontFamily: 'Readex Pro' }}>
                فحص الأنظمة الهندسية
              </Typography>
              <Typography sx={{ mb: 4, fontFamily: 'Readex Pro', marginTop: '2rem' }}>
                نقوم بتقييم الأنظمة الكهربائية والصحية والميكانيكية للمبنى للتحقق من أنها تعمل بشكل صحيح وفقًا للمعايير المطلوبة.
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Item>
              <SettingsIcon sx={{ fontSize: 70, color: '#D97A07' }} />
              <Typography variant="h5" sx={{ mb: 2, fontFamily: 'Readex Pro' }}>
                تقييم الصيانة والتشغيل
              </Typography>
              <Typography sx={{ mb: 4, fontFamily: 'Readex Pro', marginTop: '2rem' }}>
                نقوم بتقييم الصيانة والتشغيل لضمان سلامة وقوة هيكل المبنى.
              </Typography>
            </Item>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <Item>
              <AssignmentIcon sx={{ fontSize: 70, color: '#D97A07' }} />
              <Typography variant="h5" sx={{ mb: 2, fontFamily: 'Readex Pro' }}>
                تقديم تقارير شاملة
              </Typography>
              <Typography sx={{ mb: 4, fontFamily: 'Readex Pro', marginTop: '2rem' }}>
                نقدم تقارير شاملة توضح حالة المبنى وتوصيات للصيانة والتحسينات اللازمة.
              </Typography>
            </Item>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Services;
