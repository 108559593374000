
import React from 'react';
import './App.css';
import Navbar from './Components/Navbar/Navbar';
import images from "../src/data/images";
import Header from './Components/Header/Header';
import About from './Components/About/About';
import Services from './Components/services/Services';
import Gallery from './Components/Gallery/Gallery';
import gallery from './data/gallery';
import Clients from './Components/clients/Clients';
import Success from './Components/success/Success';
import Contact from './Components/Contact/Contact';
import Footer from './Components/Footer/Footer';
import { BrowserRouter, Router } from 'react-router-dom';
function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Navbar></Navbar>
     
      <Header>
        {images.map((image, index) => {
          return <img key={index} src={image.img} alt={image.imgAlt} />;
        })}
      </Header>
      <About></About>
      <Services></Services>
      <Gallery>
      {gallery.map((gall, index) => {
          return <img key={index} src={gall.img} alt={gall.imgAlt} />;
        })}
      </Gallery>
      <Clients></Clients>
      <Success></Success>
      <Contact></Contact>
      <Footer></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
