import * as React from 'react';
import { useTrail, animated } from '@react-spring/web';
import { Box, Grid, Card, Typography } from '@mui/material';

const cards = [
  {
    details: 'تواصلت معهم قبل ماشتري بيتي ماقصروا عملوا الفحص وعطوني تقرير تعاملهم جدا مميز',
    name: 'عبدالعزيز',
    bg: '#D97A07',
    textbg: '#F3F3F3',
    text: 'black',
  },
  {
    details: 'طلبتهم قبل مايتم افراغ عقد البيت وفحصوا بيت كان عاجبني طلع في بعض المشاكل والحمدلله اننا ماشتريناه طلع فيه بعد فترة هبوط بجدران الحوش',
    name: 'ام خالد',
    bg: '#D97A07',
    textbg: '#F3F3F3',
    text: 'black',
  },
  {
    details: 'عملوا لي تقرير للشقة اللي اشتريتها وطلع فيها مشاكل في تسريب المياه والحمدالله أفادني جدا',
    name: 'أبو نايف',
    bg: '#D97A07',
    textbg: '#F3F3F3',
    text: 'black',
  },
  {
    details: 'عملوا لي فحص فيلا تبعي بالخرج والحمدلله كانوا سببا بعد الله في اختياري للبيت العمر',
    name: 'أم محمد',
    bg: '#D97A07',
    textbg: '#F3F3F3',
    text: 'black',
  },
];

export default function Clients() {
  const trail = useTrail(cards.length, {
    from: { opacity: 0, transform: 'translate3d(0,40px,0)' },
    to: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    delay: 200,
  });

  return (
    <Box sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: '4rem', margin: '4rem' }}>
      <Typography
        style={{
          fontSize: '35px',
          fontWeight: 'bold',
          lineHeight: '1',
          textDecoration: 'underline',
          textDecorationColor: '#ff6900',
          fontFamily: 'Readex Pro',
          color: 'rgb(0, 34, 63)',
        }}
      >
        أراء عملائنا
      </Typography>
      <Box sx={{ flexGrow: 1, marginTop: '2rem', direction: 'rtl' }}>
        <Grid sx={{margin:{xs:'0rem',sm:'6rem'}}} container spacing={2} justifyContent="center">
          {trail.map((style, index) => (
            <Grid item xs={12} sm={6} key={index}>
              <animated.div style={style}>
                <Card
                  sx={{
                    maxWidth: 400, // Reduced max-width for smaller screens
                    margin: '1rem',
                    borderRadius: '15px',
                    boxShadow: 3,
                    border: '1px solid black',
                    backgroundColor: cards[index].bg,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      mb: 2,
                      fontFamily: 'Readex Pro',
                      margin: '2rem',
                      backgroundColor: cards[index].textbg,
                      color: cards[index].text,
                      borderRadius: '15px',
                      padding: '1rem',
                      textAlign: 'right', // Adjusted for right-to-left text
                      fontSize: '16px', // Reduced font size for smaller screens
                    }}
                  >
                    {cards[index].details}
                  </Typography>
                  <Typography
                    sx={{
                      mb: 4,
                      fontFamily: 'Readex Pro',
                      marginTop: '2rem',
                      textAlign: 'right',
                      marginRight: '3rem', // Adjusted for right-to-left text
                      textDecoration: 'underline',
                      fontWeight: 'bold',
                      color: 'rgb(0, 34, 63)',
                      fontSize: '18px', // Reduced font size for smaller screens
                    }}
                  >
                    {cards[index].name}
                  </Typography>
                </Card>
              </animated.div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
