import React, { useState, useEffect } from "react";
import "./Header.css";

function Header({ children }) {
  const [hovered, setHovered] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [slideDone, setSlideDone] = useState(true);
  const [timeID, setTimeID] = useState(null);

  useEffect(() => {
    if (slideDone) {
      setSlideDone(false);
      setTimeID(
        setTimeout(() => {
          slideNext();
          setSlideDone(true);
        }, 5000)
      );
    }
  }, [slideDone]);

  const slideNext = () => {
    setActiveIndex((val) => (val >= children.length - 1 ? 0 : val + 1));
  };

  const slidePrev = () => {
    setActiveIndex((val) => (val <= 0 ? children.length - 1 : val - 1));
  };

  const AutoPlayStop = () => {
    if (timeID > 0) {
      clearTimeout(timeID);
      setSlideDone(false);
    }
  };

  const AutoPlayStart = () => {
    if (!slideDone) {
      setSlideDone(true);
    }
  };

  const buttonStyle = {
    display: 'inline-block',
    marginTop: '50px',
    padding: '15px 25px',
    backgroundColor: hovered ? '#9b5704' : '#D97A07',
    fontFamily: 'Readex Pro',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '20px',
    transform: hovered ? 'scale(1.1)' : 'scale(1)',
    transition: 'background-color 0.3s ease, color 0.3s ease, transform 0.3s ease'
  };
  const handleButtonClick = () => {
    // Replace with your WhatsApp API link
    window.open('https://api.whatsapp.com/send/?phone=966556663904&text&type=phone_number&app_absent', '_blank');
  };

  return (
    <div id="header"
      className="container__slider"
      onMouseEnter={AutoPlayStop}
      onMouseLeave={AutoPlayStart}
    >
      {children.map((item, index) => (
        <div
          className={`slider__item ${activeIndex === index ? "active" : ""}`}
          key={index}
        >
          {item}
          <div className={`text-overlay ${activeIndex === index ? "active" : ""}`}>
            <div className="overlay-content">
              <span className="text-content">المهندس عبدالله الحمدان للفحص الهندسي</span>
              <button onClick={handleButtonClick}
                className="overlay-button"
                style={buttonStyle}
                onMouseEnter={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
              >
                طلب الخدمة
              </button>
            </div>
          </div>
        </div>
      ))}

      <div className="container__slider__links">
        {children.map((item, index) => (
          <button
            key={index}
            className={`dot ${activeIndex === index ? "active" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              setActiveIndex(index);
            }}
          ></button>
        ))}
      </div>

      <button
        className="slider__btn slider__btn-next"
        onClick={(e) => {
          e.preventDefault();
          slideNext();
        }}
      >
        {">"}
      </button>
      <button
        className="slider__btn slider__btn-prev"
        onClick={(e) => {
          e.preventDefault();
          slidePrev();
        }}
      >
        {"<"}
      </button>
    </div>
  );
}

export default Header;
