import * as React from 'react';
import { useTrail } from '@react-spring/web';
import { Box, Typography } from '@mui/material';

const embedMapStyles = {
  maxWidth: '100%',
  overflow: 'hidden',
  width: '100%',
  height: '500px', // Adjusted height for the map container
  border: '2px solid black',
  
};

export default function Contact() {
  const trail = useTrail(5, {
    from: { opacity: 0, transform: 'translate3d(0,40px,0)' },
    to: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    delay: 200,
  });

  return (
    <Box id="contact" sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: '0rem', margin: '4rem' }}>
      <Typography
        style={{
          fontSize: '35px',
          fontWeight: 'bold',
          lineHeight: '1',
          textDecoration: 'underline',
          textDecorationColor: '#ff6900',
          fontFamily: 'Readex Pro',
          color: 'rgb(0, 34, 63)',
        }}
      >
        نتشرف بزيارتكم
      </Typography>
      <Box sx={{ flexGrow: 1, marginTop: '2rem', direction: 'rtl', maxWidth: '100%' }}>
        <div style={embedMapStyles}>
          <div id="embed-map-canvas" style={{ height: '100%', width: '100%', maxWidth: '100%' }}>
            <iframe
              style={{ height: '100%', width: '100%', border: '0' }}
              frameBorder="0"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1855752.9620374292!2d49.19011308690645!3d24.70910776502912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f03890d489399%3A0xba974d1c98e79fd5!2z2KfZhNix2YrYp9i2INin2YTYs9i52YjYr9mK2Kk!5e0!3m2!1sar!2seg!4v1697100556634!5m2!1sar!2seg"
              allowFullScreen
              title="Embedded Google Map"
            ></iframe>
          </div>
        </div>
      </Box>
    </Box>
  );
}
