import seven from '../imagesfor/7.png'
import eight from '../imagesfor/8.png'
import nine from '../imagesfor/9.png'
import ten from '../imagesfor/10.png'
import eleven from '../imagesfor/11.png'




const gallery = [
  {
    img:seven
     ,
    imgAlt: "img-1"
  },
  {
    img:eight
      ,
    imgAlt: "img-2"
  },
  {
    img: nine
      ,
    imgAlt: "img-3"
  },
  {
    img:
      ten,
    imgAlt: "img-4"
  },
  {
    img:
      eleven,
    imgAlt: "img-4"
  },
  
];

export default gallery;
