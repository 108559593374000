import one from '../imagesfor/1.png'
import two from '../imagesfor/2.png'
import three from '../imagesfor/3.png'
import four from '../imagesfor/4.png'
import five from '../imagesfor/5.png'
import six from '../imagesfor/6.png'



const images = [
  {
    img:one
     ,
    imgAlt: "img-1"
  },
  {
    img:two
      ,
    imgAlt: "img-2"
  },
  {
    img: three
      ,
    imgAlt: "img-3"
  },
  {
    img:
      four,
    imgAlt: "img-4"
  },
  {
    img:
      five,
    imgAlt: "img-4"
  },
  {
    img:
      six,
    imgAlt: "img-4"
  }
];

export default images;
