import React from 'react';
import { useTrail, animated } from '@react-spring/web';
import { Box, Grid, Card, Typography } from '@mui/material';
import s1 from '../../imagesfor/s1.jpg';
import s2 from '../../imagesfor/s2.jpg';
import s3 from '../../imagesfor/s3.jpg';
import s4 from '../../imagesfor/s4.jpg';
import s5 from '../../imagesfor/s5.jpg';

const ss = [
  { img: s1 },
  { img: s2 },
  { img: s3 },
  { img: s4 },
  { img: s5 }
];

export default function Success() {
  const trail = useTrail(ss.length, {
    from: { opacity: 0, transform: 'translate3d(0,40px,0)' },
    to: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    delay: 200,
  });

  return (
    <div
      style={{
        background: 'linear-gradient(to right, #00223f, #0056a6)',
        paddingBottom: '2rem',
      }}
    >
      <Box
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          marginTop: '4rem',
          margin: '4rem',
        }}
      >
        <Typography
          sx={{
            fontSize: '35px',
            fontWeight: 'bold',
            lineHeight: '1',
            textDecoration: 'underline',
            textDecorationColor: '#ff6900',
            fontFamily: 'Readex Pro',
            color: 'white',
            paddingTop: '4rem',
          }}
        >
          شركاء النجاح
        </Typography>
        <Box sx={{ flexGrow: 1, marginTop: '2rem', direction: 'rtl' }}>
          <Grid
            sx={{ margin: { xs: '0rem', sm: '4rem' }, justifyContent: 'center' }}
            container
            spacing={2}
          >
            {trail.map((style, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <animated.div style={style}>
                  <Card
                    sx={{
                      maxWidth: 500,
                      margin: '1rem',
                      borderRadius: '15px',
                      boxShadow: 3,
                      border: '1px solid black',
                      backgroundColor: '#F3F3F3',
                    }}
                  >
                    <img
                      src={ss[index].img}
                      alt={`success-${index}`}
                      style={{ width: '100%', borderRadius: '15px' }}
                    />
                  </Card>
                </animated.div>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </div>
  );
}
