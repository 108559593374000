import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import two from '../../imagesfor/2.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

const About = () => {
  const [isHovered, setIsHovered] = useState(false);

  const iconStyle = {
    fontSize: 80,
    marginTop: '2rem',
    cursor: 'pointer',
    color: isHovered ? 'darkgreen' : 'green',
    transition: 'color 0.3s ease' // Smooth transition for color change
  };

  return (
    <div className='main-2'>
      <Box id="about" sx={{ justifyContent: 'center', alignItems: 'center', textAlign: 'center', marginTop: '4rem', margin: '4rem' }}>
        <Typography
          style={{
            fontSize: '35px',
            fontWeight: 'bold',
            marginBottom: '0.2rem',
            lineHeight: '1',
            textDecoration: 'underline',
            textDecorationColor: '#ff6900',
            fontFamily: 'Readex Pro',
            color: 'rgb(0, 34, 63)'
          }}>
          نبذة عنا
        </Typography>
        <Typography
          style={{
            fontSize: '25px',
            marginBottom: '6rem',
            marginTop: '2rem',
            fontFamily: 'Readex Pro',
            color: 'rgb(0, 34, 63)'
          }}>
          مهندسون مختصون في فحص وتقييم المباني هندسيًا، ونفخر بأننا موثقون من قبل وزارة الإسكان فاحص رقم / 2069187367 ، نقدم خدماتنا المتخصصة للعملاء الذين يبحثون عن تقييم دقيق لحالة المباني ومعرفة مدى توافقها مع المعايير الهندسية المحلية والدولية
        </Typography>
        <Typography
          className='top'
          style={{
            fontSize: '22px',
            fontFamily: 'Readex Pro',
            color: 'rgb(0, 34, 63)'
          }}>
          Thank you for reading this post, don't forget to subscribe!
        </Typography>
        <Typography
          className='top'
          style={{
            fontSize: '22px',
            marginBottom: '4rem',
            fontFamily: 'Readex Pro',
            color: 'rgb(0, 34, 63)'
          }}>
          تتضمن خبرتنا الواسعة التي تمتد الى 10 سنوات تقييم المباني السكنية والتجارية والصناعية والعامة
        </Typography>
        <Typography
          className='top'
          style={{
            fontSize: '20px',
            marginBottom: '6rem',
            fontFamily: 'Readex Pro',
            color: 'rgb(0, 34, 63)'
          }}>
          و نستخدم أحدث التقنيات والمعايير الهندسية لتقديم تقارير دقيقة وشاملة توفر معلومات قيمة للعملاء
        </Typography>
      </Box>
      <Box
        sx={{
          backgroundImage: `linear-gradient(to right, rgba(0, 34, 63, 0.7), rgba(0, 34, 63, 0)), url(${two})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '100%',
          minHeight: '500px', // Adjusted height for responsiveness
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          padding: '20px',
          position: 'relative'
        }}
      >
        <Grid container justifyContent="center">
          <Grid item xs={12} md={6}>
            <Box id="about1" sx={{ textAlign: 'center' }}>
              <Typography variant="h4" sx={{ mb: 2, fontFamily: 'Readex Pro', textDecoration: 'underline', textDecorationColor: '#9b5704', fontWeight: 'bold' }}>
                إدارة المهندس عبدالله الحمدان
              </Typography>
              <Typography variant="h6" sx={{ mb: 4, fontFamily: 'Readex Pro', marginTop: '4rem' }}>
                مهندس متخصص في تقييم المباني والفلل بخبرة تتجاوز 15 عامًا
              </Typography>
             <Link to ='https://api.whatsapp.com/send/?phone=966556663904&text&type=phone_number&app_absent'><FontAwesomeIcon
                icon={faWhatsapp}
                style={iconStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              /></Link> 
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default About;
